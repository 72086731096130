import { extractErrorMessage } from "../../utils/http";


export default function InputError({ error }) {
  return error && (
    <div className="bg-red-600 sm:rounded-b-vw-3xl rounded-b-vw-6xl relative z-[-1]">
      <div className="sm:py-vw-1 py-vw-2 sm:px-vw-8 px-vw-16">
        <p className="sm:text-vw-base text-vw-3xl text-white">
          {extractErrorMessage(error)}
        </p>
      </div>

      <div className="w-full sm:h-vw-8 h-vw-16 bg-red-600 absolute bottom-full" />
    </div>
  )
}
