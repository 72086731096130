import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import DoubleCheckmarkSvg from '../../public/svg/double-checkmark.svg'


function SuccessOverlay({ onClose }) {
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => {
      document.body.classList.remove('locked');
      onClose?.()
    }, 160)
  }

  useEffect(() => {
    setVisible(true);
    document.body.classList.add('locked');
  }, [])

  return createPortal(
    <div className="fixed inset-0 z-[100] transition-opacity" style={{ opacity: visible ? 1 : 0 }}>
      <div className="centered w-full h-full backdrop-blur-lg bg-black/20 px-main">
        <div className="centered flex-col sm:w-vw-220 w-full bg-gray rounded-vw-5xl sm:py-vw-20 py-vw-32">
          <DoubleCheckmarkSvg className="sm:w-vw-32 w-vw-40 [&>*]:fill-primary sm:-mt-vw-14 -mt-vw-10" />

          <h3 className="text-vw-6xl font-bold text-center leading-none sm:mt-vw-6 mt-vw-10">
            Thank you<br/>for ordering
          </h3>
          <p className="sm:text-vw-lg text-vw-3xl text-center mt-vw-6">
            We will get back to you as<br/>soon as possible
          </p>

          <button
            type="button"
            onClick={handleClose}
            className="big-ass-button sm:px-vw-18 px-vw-28 sm:mt-vw-14 mt-vw-20"
          >
            Ok
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('__portals')
  )
}

SuccessOverlay.propTypes = {};

SuccessOverlay.defaultProps = {};


export default SuccessOverlay;
