import { useFormContext, get } from "react-hook-form"

import InputPlaceholder from "./InputPlaceholder";
import InputError from "./InputError";


export default function TextInput({ name, rules, type='text', placeholder, className='', readonly=false }) {
  const { register, watch, errors } = useFormContext();
  const value = watch(name);
  const error = get(errors, name);

  return (
    <label className={className}>
      <div className="relative">
        <input
          {...register(name, rules)}
          type={type}
          className={`form-text-input w-full ${value ? 'sm:pt-vw-3.5 pt-vw-10' : ''}`}
          readOnly={readonly}
        />

        <InputPlaceholder placeholder={placeholder} value={value} />
      </div>

      <InputError error={error} />
    </label>
  )
}
