import { useEffect, useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";

import { createOrder } from "../api/wagtail";


//defaultValues, onSubmit, onSuccess, onWatch, className = '', children
export function OrderContextProvider({ products, csrfToken, children }) {
  const {
    handleSubmit,
    watch,
    setError,
    formState: { isSubmitting, errors },
    ...methods
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      emirate: 'Dubai',
      city: '',
      address: '',
      apartment: '',
      policy: true,
      interiorStyle: null,
      apartmentType: null
    }
  });
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const submit = async (data) => {
    try {
      // Get the current URL
      const url = typeof window !== 'undefined' ? window.location.href : '';

      const resp = await createOrder({ ...data, url }, { csrfToken });
      setHasSubmitted(true);
      // Google Analytics event tracking for successful form submission
      
      if (typeof window !== "undefined" && typeof window.gtag === "function") {
        window.gtag('event', 'submit', {
          'event_category': 'Form',
          'event_label': 'Form Submission',
          'value': 1
        });
        window.gtag('event', 'package_form_submit', {
          'event_category': 'Form',
          'event_label': 'Form Submission',
          'value': 1,
        });
      }
      
      fbq('init', '24372893972294889');
      fbq('track', 'Lead');
      return resp;
    } catch (err) {
      if (typeof err.data.error === 'object') {
        if (err.data.error.details) {
          setError('root.serverError', {
            type: err.response.status,
            message: err.response.statusText
          })
          return
        }

        for (let [name, errData] of Object.entries(err.data.error)) {
          setError(name, {
            type: 'server',
            message: Array.isArray(errData) ? errData.join(', ') : errData
          })
        }
      }
    }
  }

  const formProducts = watch('products', {});

  const totalPrice = (products || []).reduce(
    (price, prod) => {
      const formProd = formProducts[prod.id];
      return formProd ? price += (prod.price * formProd.quantity) : price;
    },
    0
  );

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(data, name, type)
      }
    });
    return () => subscription.unsubscribe();
  })

  return (
    <FormProvider {...{ totalPrice, watch, errors, setError, isSubmitting, hasSubmitted, ...methods }}>
      <form onSubmit={handleSubmit(submit)}>
        {children}
      </form>
    </FormProvider>
  )
}


export const useOrderContext = () => useFormContext();
