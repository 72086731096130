import React, { useEffect, useState } from 'react';
import FAQ from '../../../components/FAQ';

export default function FAQSection({ className='' }) {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    fetch('/wt/api/core/v1/faqs/?fields=*')
      .then(res => res.json())
      .then(data => {
        setFaqs(data.items);
      })
      .catch(error => console.error('Error fetching FAQs:', error));
  }, []);

  if (faqs.length === 0) {
    return null; // or some loading indicator if you prefer
  }

  return (
    <section className={`container-main faq-container flex flex-wrap sm:flex-nowrap ${className}`}>
      <div className="w-full sm:w-vw-76 shrink-0">
        <h2 className="mb-vw-12 text-vw-8xl font-bold leading-min relative z-10">FAQ</h2>
      </div>
      <div className="w-full relative">
        <FAQ faqs={faqs} />
      </div>
    </section>
  );
}
