import { useController } from "react-hook-form";


export default function QuantityInput({ name, rules: { min=0, max, disabled, ...rules } = {}, orientation='vertical', className='' }) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    defaultValue: 1,
    rules: {
      ...rules,
      min: min,
      max: max
    },
  });

  return (
    <div
      className={`
        ${disabled ? 'opacity-50 pointer-events-none' : ''}
        ${orientation === 'vertical' ? 'centered-x flex-col' : 'centered-y'} ${className}
      `}
    >
      <button
        type="button"
        className={`
          centered sm:w-vw-7 w-vw-18 sm:h-vw-7 h-vw-18 bg-gray rounded-full
          ${![null, undefined].includes(max) ? value >= max ? 'opacity-30 pointer-events-none' : '' : ''}
        `}
        onClick={() => onChange?.(value + 1)}
      >
        <svg className="sm:w-vw-2.5 w-vw-6 opacity-30" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.73636 9V0H5.26364V9H3.73636ZM0 5.26364V3.73636H9V5.26364H0Z" fill="black" />
        </svg>
      </button>

      <div className={`centered sm:w-vw-7 w-vw-18 sm:h-vw-7 h-vw-18 text-center`}>
        <p className="sm:text-vw-base text-vw-4xl">{value}</p>
      </div>

      <button
        type="button"
        className={`
          centered sm:w-vw-7 w-vw-18 sm:h-vw-7 h-vw-18 bg-gray rounded-full
          ${![null, undefined].includes(min) ? value <= min ? 'pointer-events-none' : '' : ''}
        `}
        onClick={() => onChange?.(value - 1)}
      >
        <svg className="sm:w-vw-2.5 w-vw-6 opacity-30" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 5.26361V3.73633H9V5.26361H0Z" fill="black" />
        </svg>
      </button>
    </div>
  )
}
