import { useController } from "react-hook-form"


export default function Checkbox({ name, rules, label, className='' }) {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ name, rules })

  return (
    <label
      className={`flex items-center ${className}`}
      onClick={() => onChange(!value)}
    >
      <div
        className={`
          centered flex-shrink-0 sm:w-vw-10 w-vw-18 sm:h-vw-10 h-vw-18 bg-white rounded-full
          outline outline-6 ${error ? 'outline-red-600' : 'outline-transparent'}
          transition-[outline]
        `}
      >
        <div
          className={`
          sm:w-vw-7 w-vw-11 sm:h-vw-7 h-vw-11
          ${value === true ? 'bg-primary' : ''} rounded-full
          transition-colors
        `}
        />
      </div>

      <p className="sm:text-vw-xl text-vw-4xl sm:ml-vw-2 ml-vw-4">
        {label}
      </p>
    </label>
  )
}
