import { useRef, useEffect, useState } from 'react'

import { useShopContext } from '../../contexts/ShopContext';
import { useOrderContext } from '../../contexts/OrderContext';
import { spacedNumber } from '../../utils/numbers'


export default function ShopSummaryBox({ variant, hidden, isNext, onNext }) {
  const {
    interiorStyles,
    currentInteriorStyle,
    setCurrentInteriorStyle,
    apartmentTypes,
    currentApartmentType,
    setCurrentApartmentType,
    getProductsCount
  } = useShopContext();

  const { isSubmitting, totalPrice } = useOrderContext();
  const mobileFixedRef = useRef(null);
  const [mobileFixedHidden, setMobileFixedHidden] = useState(true);
  const [mobileFixedCollapsed, setMobileFixedCollapsed] = useState(true);

  const lowerCapPrice = totalPrice < 10_000 ? 10_000 : totalPrice;

  const interiorStyleData = interiorStyles.find(s => s.slug === currentInteriorStyle);
  const apartmentTypeData = apartmentTypes.find(p => p.slug === currentApartmentType);

  useEffect(() => {
    const shopSection = document.getElementById('shop');

    if (mobileFixedRef.current && shopSection && !hidden) {
      const handleScroll = () => {
        setMobileFixedHidden(
          window.scrollY >= (shopSection.offsetTop + shopSection.offsetHeight - 500)
            ? true
            : window.scrollY >= (shopSection.offsetTop - 100)
              ? false
              : true
        );
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [mobileFixedRef])  // eslint-disable-line

  useEffect(() => {
    if (hidden === false) {
      setMobileFixedHidden(false);
    }
  }, [hidden])

  useEffect(() => {
    if (!mobileFixedHidden) {
      document.body.dataset.shopInfoShown = 'true';
    } else {
      document.body.dataset.shopInfoShown = 'false';
    }
  }, [mobileFixedHidden])

  if (variant === 'desktop-sidebar') {
    return (
      <aside className="desktop-only w-full bg-black text-white rounded-vw-4xl p-vw-7">
        {/* <p className="text-vw-base">Total products count: {getTotalProductsCount()}</p> */}

        <p className="text-vw-base">Interior style</p>

        <div className="mt-vw-2">
          {interiorStyles.map((stl, idx) => (
            <button
              key={idx}
              type="button"
              className={`centered-y ${idx !== 0 ? 'mt-vw-2' : ''}`}
              onClick={() => setCurrentInteriorStyle(stl.slug)}
            >
              <div
                className={`
                  w-vw-7 h-vw-7
                  rounded-full ring-6 ${currentInteriorStyle === stl.slug ? 'ring-primary' : 'ring-transparent'}
                  transition-[box-shadow]
                `}
                style={{ backgroundColor: stl.color }}
              />
              <p className="text-vw-sm ml-vw-2">
                {stl.name}
              </p>
            </button>
          ))}
        </div>

        <p className="text-vw-base mt-vw-6">Apartment type</p>

        <div className="mt-vw-2">
          {apartmentTypes.map((type, idx) => (
            <button
              key={idx}
              type="button"
              className={`
                centered flex-grow h-vw-10
                ${currentApartmentType === type.slug ? 'bg-primary' : 'bg-white/30 opacity-40'}
                ${idx !== 0 ? 'mt-vw-1.5' : ''}
                rounded-vw-xl transition-colors px-vw-5
              `}
              onClick={() => setCurrentApartmentType(type.slug)}
            >
              <p className="text-vw-sm text-white">
                {type.name} / {getProductsCount(undefined, type.slug)} items
              </p>
            </button>
          ))}
        </div>

        <p className="text-vw-base mt-vw-6">Price</p>
        <p className="text-vw-xl">
          <b>{`${lowerCapPrice ? spacedNumber(lowerCapPrice) : '---'} AED`}</b>
        </p>
        <p className="text-vw-sm">Minimal order: 10 000 AED</p>

        {!isNext ? (
          <button
            key="form-next"
            type="button"
            className="big-ass-button w-full text-center outline outline-6 -outline-offset-6 outline-primary relative !px-0 mt-vw-5"
            onClick={onNext}
          >
            Next
          </button>
        ) : (
          <button
            key="form-submit"
            type="submit"
            className="big-ass-button w-full text-center outline outline-6 -outline-offset-6 outline-primary relative !px-0 mt-vw-5"
            style={{ pointerEvents: isSubmitting ? 'none' : '' }}
          >
            {/* Go to payment */}
            Submit

            {isSubmitting && (
              <svg className="w-full absolute top-0 left-0" viewBox="0 0 336 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M36 3H300C318.225 3 333 17.7746 333 36V51.75C333 69.9754 318.225 84.75 300 84.75H36C17.7746 84.75 3 69.9754 3 51.75V36C3 17.7746 17.7746 3 36 3Z"
                  stroke="white"
                  strokeWidth={6}
                  strokeDasharray={380}
                >
                  <animate attributeName="stroke-dashoffset" values="2037;0;2037" dur="10s" repeatCount="indefinite" />
                </path>
              </svg>
            )}
          </button>
        )}
      </aside>
    )
  } else if (variant === 'mobile-fixed') {
    return (
      <div
        ref={mobileFixedRef}
        className="mobile-only fixed inset-x-vw-10 bottom-vw-10 z-[10000] transition-transform"
        style={{
          transform: mobileFixedHidden
            ? 'translateY(calc(100% + clamp(20px, 3.125vw, 60px) + clamp(52px, 8.125vw, 156px)))'
            : 'translateY(0vw)'
        }}
      >
        <div
          className="text-white bg-black rounded-vw-5xl overflow-hidden transition-[height]"
          style={{ height: mobileFixedCollapsed ? 'clamp(72px, 11.25vw, 216px)' : 'clamp(216px, 33.75vw, 648px)' }}
        >
          <div
            className="centered-y flex-shrink-0 h-vw-36 transition-transform px-vw-10"
            onClick={() => setMobileFixedCollapsed(false)}
            style={{ transform: mobileFixedCollapsed ? 'translateY(0%)' : 'translateY(-100%)' }}
          >
            <div>
              <p className="text-vw-2xl">Price</p>
              <p className="text-vw-2xl font-bold">
                {`${lowerCapPrice ? spacedNumber(lowerCapPrice) : '---'} AED`}
              </p>
            </div>

            <div className="centered flex-grow">
              <div
                className="w-vw-16 h-vw-16 rounded-full"
                style={{ backgroundColor: interiorStyleData?.color }}
              />
              <p className="text-vw-2xl ml-vw-3">
                {interiorStyleData?.name}
              </p>
            </div>

            <div className="flex-shrink-0 centered-y">
              <button
                type="button"
                className={`
                  centered flex-grow h-vw-16
                  bg-white text-black rounded-vw-2xl
                  transition-colors px-vw-6
                `}
              >
                <p className="text-vw-xl">
                  {apartmentTypeData?.name} / {apartmentTypeData ? getProductsCount() : '?'} items
                </p>
              </button>
            </div>
          </div>

          <div
            className="flex w-full h-vw-108 transition-transform px-vw-10 pt-vw-10"
            style={{ transform: mobileFixedCollapsed ? 'translateY(100%)' : 'translateY(clamp(-72px, -11.25vw, -216px))' }}
          >
            <div className="flex-grow">
              <p className="text-vw-2xl">Price</p>

              <p className="text-vw-2xl font-bold">
                {`${lowerCapPrice ? spacedNumber(lowerCapPrice) : '---'} AED`}
              </p>
            </div>

            <div className="flex-shrink-0 w-vw-40">
              <p className="text-vw-2xl">Interior<br/>style</p>

              <div className="flex flex-col items-start mt-vw-6">
                {interiorStyles.map((stl, idx) => (
                  <button
                    key={idx}
                    type="button"
                    className={`centered-y ${idx !== 0 ? 'mt-vw-3' : ''}`}
                    onClick={() => setCurrentInteriorStyle(stl.slug)}
                  >
                    <div
                      className={`
                        centered w-vw-20 h-vw-20
                        rounded-full ring-4 ${currentInteriorStyle === stl.slug ? 'ring-primary' : 'ring-transparent'}
                        transition-[box-shadow]
                      `}
                      style={{ backgroundColor: stl.color }}
                    >
                      <p className="text-vw-xl" style={{ color: stl.textColor }}>
                        {stl.name}
                      </p>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            <div className="flex-shrink-0">
              <p className="text-vw-2xl">Apartment type</p>

              <div className="flex flex-col items-start mt-vw-6">
                {apartmentTypes.map((type, idx) => (
                  <button
                    key={idx}
                    type="button"
                    className={`
                      centered flex-grow h-vw-16
                      ${currentApartmentType === type.slug ? 'bg-primary' : 'bg-white/30 opacity-40'}
                      ${idx !== 0 ? 'mt-vw-2' : ''}
                      rounded-vw-2xl transition-colors px-vw-8
                    `}
                    onClick={() => setCurrentApartmentType(type.slug)}
                  >
                    <p className="text-vw-xl text-white">
                      {type.name} / {getProductsCount(undefined, type.slug)} items
                    </p>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="centered w-vw-20 h-vw-20 bg-black rounded-full absolute top-0 left-vw-10 z-3 -translate-y-1/2"
          onClick={() => setMobileFixedCollapsed(!mobileFixedCollapsed)}
        >
          <svg className={`w-vw-8 h-vw-8 ${mobileFixedCollapsed ? '' : 'rotate-180'} transition-transform`} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fff" fillRule="evenodd" clipRule="evenodd" d="M9 3.00006L8.99994 3L6.87589 5.12404L6.87593 5.12408L0 11.9999L2.12404 14.1239L9 7.2481L15.876 14.1239L18 11.9999L9.24872 3.24878L9 3.00006Z" />
          </svg>
        </button>

        <button
          type="button"
          onClick={onNext}
          className="h-vw-26 bg-primary rounded-t-vw-4xl absolute top-vw-4 right-vw-4 z-[-1] transition-transform px-vw-20 pb-vw-4"
          style={{ transform: isNext ? 'translateY(0%)' : 'translateY(-100%)' }}
        >
          <p className="text-vw-3xl font-bold text-white">
            Next
          </p>
        </button>
      </div>
    )
  } else {
    return null;
  }
}
