export default function InputPlaceholder({ placeholder, value }) {
  return (
    <div className="absolute sm:left-vw-8 left-vw-16 top-1/2 pointer-events-none cursor-text transform -translate-y-1/2">
      <p
        className={`
          form-placeholder
          transform origin-left scale
          ${value ? 'scale-[.7] sm:mb-vw-6 mb-vw-14' : ''}
          transition-all
        `}
      >
        {placeholder}
      </p>
    </div>
  )
}
