import Image from 'next/image';

import CameraSvg from '../../../public/svg/camera.svg';
import photographerBannerImage from '../../../public/img/photographer-banner.jpg'


export default function PhotographerSection({ className='' }) {
  return (
    <section className={`container-main ${className}`}>
      <div className="w-full sm:h-vw-108 h-vw-188 relative">
        <Image
          className="object-cover"
          src={photographerBannerImage}
          alt="new form - after delivery"
          fill={true}
        />

        <div className="absolute sm:left-vw-20 left-vw-14 sm:bottom-vw-10 bottom-vw-24 z-10">
          <CameraSvg className="sm:w-vw-18 w-vw-24 sm:mb-vw-8 mb-vw-12" />

          <h1 className="text-vw-6xl text-white font-bold">
            After delivery & assembly our<br />photographer will take stunning<br />photos of your apartment
          </h1>
        </div>
      </div>
    </section>
  )
}
