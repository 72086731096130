import Image from 'next/image';

import DoubleCheckmarkSvg from '../../../public/svg/double-checkmark.svg';
import { useShopContext } from '../../../contexts/ShopContext';
import Slider from "react-slick";
import NextArrowSvg from '../../../public/svg/next-arrow.svg';
import PrevArrowSvg from '../../../public/svg/prev-arrow.svg';

// Using the context to get various properties and methods related to the shop.
export default function HowItWorksSection({ className='' }) {
  const { interiorStyles, currentInteriorStyle, setCurrentInteriorStyle, apartmentTypes, getProductsCount, galleryImages, loading } = useShopContext();

  // Function to change the current interior style and optionally scroll to a specific section.
  const goToStyle = (stl) => {
    setCurrentInteriorStyle(stl);
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      className={`${className} w-auto h-8 sm:h-[4vw] right-8 sm:right-12 before:content-none z-10`}
      style={{ ...style, display: "block" }}
      onClick={onClick}>
         <NextArrowSvg className="h-full max-h-full"/>
    </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      className={`${className} w-auto h-8 sm:h-[4vw] left-8 sm:left-12 before:content-none z-10`}
      style={{ ...style, display: "block" }}
      onClick={onClick}>
         <PrevArrowSvg className="h-full max-h-full"/>
      </div>
    );
  }

  // Slick slider configuration
  const sliderSettings = {
    dots: true,  // This adds the dots at the bottom
    infinite: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <section id="how-it-works" className={`container-main relative z-10 sm:scroll-m-40 scroll-m-24 ${className}`}>
      <h2 className="text-vw-8xl font-bold leading-min">
        How it<br />works?
      </h2>

      <div className="sm:centered-y w-full sm:h-vw-72 sm:mt-0 mt-vw-24">
        <div className="flex-shrink-0 sm:mb-0 mb-vw-14">
          <h3 className="sm:text-vw-3xl text-vw-5xl font-bold sm:mb-0 mb-vw-4">
            Choose interior style
          </h3>
          <p className="sm:text-vw-3xl text-vw-4xl leading-min">
            We have 3 trendy styles<br />available to choose
          </p>
        </div>

        <div className="justify-between flex grid grid-flow-col gap-[1.8vw] sm:pl-vw-14 mb-6">
          {interiorStyles.map((stl, idx) => (
            <button
              key={idx}
              type="button"
              className={`
                centered w-[27.5vw] sm:w-[18.333333vw] h-[27.5vw] sm:h-vw-32
                rounded-[1.833333vw] relative sm:ring-[0.833333vw] ring-1 ${currentInteriorStyle === stl.slug ? 'ring-primary' : 'ring-transparent'}
                transition-[outline]
              `}
              onClick={() => goToStyle(stl.slug)}
            >
              <Image className='rounded-[1.833333vw]' src={stl.image.src} alt={stl.image.alt} fill={true} style={{objectFit: "cover"}} />
              <div
                style={{backgroundColor: stl.color}}
                className={`rounded-[1.25vw] sm:border-4 border-2 w-[3.4rem] h-[1.5em] sm:w-[9vw] sm:h-[3.666667vw] border-white absolute bottom-[-2.166667vw] bg-[#bfbfbf] flex align-center justify-center`}>
                <p
                  style={{color: stl.textColor}}
                  className="sm:text-vw-3xl text-[0.625em] font-normal m-auto">
                  {stl.name}
                </p>
              </div>
            </button>
          ))}
        </div>
      </div>
      {
        (!loading && galleryImages && galleryImages.length > 0) && (
          <div className="interior-style-slider relative">
            <Slider {...sliderSettings}>
              {galleryImages.map((imgObj, id) => (
                <div key={id} className="sm:h-[40vw] h-[108vw] relative">
                  <img src={imgObj.image.src} alt={imgObj.image.alt} style={{objectFit: "cover"}}/>
                </div>
              ))}
            </Slider>
          </div>
        )
      }
      <div className="sm:centered-y w-full sm:h-vw-72 sm:mt-0 mt-vw-28">
        <div className="flex-shrink-0">
          <h3 className="sm:text-vw-3xl text-vw-5xl font-bold sm:mb-0 mb-vw-4">
            Select <br mobile-only="" />apartment type
          </h3>
          <p className="sm:text-vw-3xl text-vw-4xl leading-min">
            Our apartment <br mobile-only="" />packages <br desktop-only="" />include
          </p>
        </div>

        <div className="sm:flex justify-between grid grid-cols-2 gap-y-vw-14 w-full sm:mt-0 mt-vw-14 sm:pl-vw-28 sm:pr-vw-14">
          {apartmentTypes.map((type, idx) => (
            <div key={idx} className={` sm:text-vw-xl text-vw-5xl sm:text-center`}>
              <p>{type.name}</p>
              <p className="text-translucent">{getProductsCount(undefined, type.slug)} items</p>
            </div>
          ))}

          <div className="sm:text-vw-xl text-vw-5xl sm:text-center">
            <p>3 bedroom<br />and more</p>
          </div>
        </div>
      </div>

      <div className="sm:centered-y w-full sm:h-vw-72 sm:mt-0 mt-vw-28">
        <div className="flex-shrink-0">
          <h3 className="sm:text-vw-3xl text-vw-5xl font-bold sm:mb-0 mb-vw-4">
            Confirm your order
          </h3>
          <p className="sm:text-vw-3xl text-vw-4xl leading-min">
            Confirm your order via<br />WhatsApp and schedule a<br />date for free delivery and<br />furniture assembly.
          </p>
        </div>

        <div className="sm:flex justify-between grid grid-cols-2 gap-y-vw-14 w-full sm:mt-0 mt-vw-14 sm:pl-vw-28 sm:pr-vw-14">
          <div className="centered-y flex-col">
            <DoubleCheckmarkSvg className="sm:w-vw-11 w-vw-24 opacity-30 sm:mb-vw-7 mb-vw-12" />
            <p className="sm:text-vw-xl text-vw-5xl">Name</p>
          </div>

          <div className="centered-y flex-col">
            <DoubleCheckmarkSvg className="sm:w-vw-11 w-vw-24 opacity-30 sm:mb-vw-7 mb-vw-12" />
            <p className="sm:text-vw-xl text-vw-5xl">Contacts</p>
          </div>

          <div className="centered-y flex-col">
            <DoubleCheckmarkSvg className="sm:w-vw-11 w-vw-24 opacity-30 sm:mb-vw-7 mb-vw-12" />
            <p className="sm:text-vw-xl text-vw-5xl">Address</p>
          </div>

          <div className="centered-y flex-col">
            <DoubleCheckmarkSvg className="sm:w-vw-11 w-vw-24 opacity-30 sm:mb-vw-7 mb-vw-12" />
            <p className="sm:text-vw-xl text-vw-5xl">Purchase</p>
          </div>
        </div>
      </div>
    </section>
  )
}
