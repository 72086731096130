import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";


// WARN: Not used anymore - replaced by OrderContext


export default function CommonForm({ defaultValues, onSubmit, onSuccess, onWatch, className='', children }) {
  const {
    handleSubmit,
    watch,
    setError,
    formState: { isSubmitting, errors, isDirty },
    ...methods
  } = useForm({ defaultValues });

  const submit = (data) => {
    return onSubmit(data)
      .then((resp) => {
        onSuccess?.(resp)
      })
      .catch((err) => {
        if (typeof err.data.error === 'object') {
          if (err.data.error.details) {
            setError('root.serverError', {
              type: err.response.status,
              message: err.response.statusText
            })
            return
          }

          for (let [name, errData] of Object.entries(err.data.error)) {
            setError(name, {
              type: 'server',
              message: Array.isArray(errData) ? errData.join(', ') : errData
            })
          }
        }
      })
  }

  const manualSubmit = () => {
    const result = handleSubmit(submit)();

  // If result is a promise (which it should be based on your current setup), add then/catch to handle the Google Analytics logic
  if (result && typeof result.then === "function") {
    result
      .then(() => {
      })
      .catch((error) => {
        // Handle the error case if needed
        console.error("Form submission error:", error);
      });
  }
  
  return result;
  }

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(data, name, type)
      }
      onWatch?.(data)
    });
    return () => subscription.unsubscribe();
  })

  return (
    <FormProvider {...{ watch, errors, setError, isSubmitting, ...methods }}>
      <form className={className} onSubmit={handleSubmit(submit)}>
        {
          typeof children === 'function'
            ? children({ submit: manualSubmit, isSubmitting, errors, isDirty, watch })
            : children
        }
      </form>
    </FormProvider>
  )
}
