import Image from 'next/image';

import BeforeAfterImage from '../../../components/BeforeAfterImage';

import wyg1 from '../../../public/img/what-will-you-get-1.jpg'
import wyg2 from '../../../public/img/what-will-you-get-2.jpg'
import wyg3part1 from '../../../public/img/what-will-you-get-3-part-1.jpg'
import wyg3part2 from '../../../public/img/what-will-you-get-3-part-2.jpg'
import wyg4 from '../../../public/img/what-will-you-get-4.jpg'
import wygBgImage from '../../../public/img/wyg-bg-mobile.png';


export default function WhatYouGetSection({ className='' }) {
  return (
    <section id="what-you-got" className={`container-main relative sm:scroll-m-40 scroll-m-24 ${className}`}>
      <div className="mobile-only absolute inset-x-0 -top-vw-116">
        <Image
          src={wygBgImage}
          alt="new form - what you got?"
          className="w-full opacity-80 mix-blend-hard-light"
          fill={true}
        />
      </div>

      <h2 className="text-vw-8xl font-bold leading-min relative z-10">
        What will<br />you get?
      </h2>

      <div className="sm:centered-y sm:flex-row flex-col w-full relative z-10 mt-vw-24">
        <div className="flex-shrink-0 sm:w-vw-160 w-full sm:h-vw-90 h-vw-96 relative">
          <Image
            className="object-cover"
            src={wyg1}
            alt="new form - what we got - our experience"
            fill={true}
          />
        </div>

        <div className="sm:w-auto w-vw-108 sm:pl-vw-7 sm:mt-0 mt-vw-12">
          <h3 className="sm:text-vw-xl text-vw-4xl font-bold mb-vw-6">
            Our experience
          </h3>
          <p className="sm:text-vw-xl text-vw-3xl">
            Our specialists know what <br desktop-only="true" />
            kind of furniture is required in <br desktop-only="true" />
            order to rent out an <br desktop-only="true" />
            apartment.
          </p>
        </div>
      </div>

      <div className="flex sm:centered-y w-full sm:mt-vw-10 mt-vw-32">
        <div className="flex-shrink-0 sm:w-vw-108 w-vw-96 sm:h-vw-148 h-vw-172 relative sm:ml-vw-24">
          <Image
            className="object-cover"
            src={wyg2}
            alt="new form - what we got - design and convenience"
            fill={true}
          />
        </div>

        <div className="sm:pl-vw-7 pl-vw-10 sm:mt-0 mt-vw-16">
          <h3 className="sm:text-vw-xl text-vw-4xl font-bold mb-vw-6">
            Design and comfort
          </h3>
          <p className="sm:text-vw-xl text-vw-3xl">
            We have checked all items in our <br desktop-only="true" />
            furniture packages. Our designers <br desktop-only="true" />
            and decorators have developed and <br desktop-only="true" />
            improved our solutions, which we <br desktop-only="true" />
            are pleased to offer you now.
          </p>
        </div>
      </div>

      <div className="sm:centered-y w-full sm:mt-vw-10 mt-vw-32">
        <div className="flex-shrink-0 sm:w-vw-188 w-full sm:h-vw-100 h-auto relative">
          <BeforeAfterImage
            leftImage={{ imageUrl: wyg3part1.src, alt: 'new form - what we got - saving time and money' }}
            rightImage={{ imageUrl: wyg3part2.src, alt: 'new form - what we got - saving time and money' }}
            className="flex w-full h-full overflow-hidden"
          />
        </div>

        <div className="sm:w-auto w-vw-108 sm:pl-vw-7 sm:mt-0 mt-vw-12">
          <h3 className="sm:text-vw-xl text-vw-4xl font-bold mb-vw-6">
            Saving time and money
          </h3>
          <p className="sm:text-vw-xl text-vw-3xl mb-vw-6">
            We constantly check prices on <br desktop-only="true" />
            the furniture market in order to <br desktop-only="true" />
            provide the best offer to our <br desktop-only="true" />
            clients.
          </p>
          <p className="sm:text-vw-xl text-vw-3xl">
            Our company has own staff of <br desktop-only="true" />repairers and furniture <br desktop-only="true" />assemblers.
          </p>
        </div>
      </div>

      <div className="flex sm:centered-y w-full sm:mt-vw-10 mt-vw-32">
        <div className="flex-shrink-0 sm:w-vw-116 w-vw-96 sm:h-vw-144 h-vw-172 relative sm:ml-vw-48">
          <Image
            className="object-cover"
            src={wyg4}
            alt="new form - what we got - decorator"
            fill={true}
          />
        </div>

        <div className="sm:pl-vw-7 pl-vw-10 sm:mt-0 mt-vw-20">
          <h3 className="sm:text-vw-xl text-vw-4xl font-bold mb-vw-6">
            Decorator
          </h3>
          <p className="sm:text-vw-xl text-vw-3xl">
            Every apartment is supervised by <br desktop-only="true" />
            our decorator, who makes <br desktop-only="true" />
            adjustments for each project and <br desktop-only="true" />
            combines new items with <br desktop-only="true" />
            belongings, that are already in the <br desktop-only="true" />
            apartment.
          </p>
        </div>
      </div>
    </section>
  )
}
