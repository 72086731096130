// import { getCookie } from './Cookie';

const parseJSON = (response) => response.json();

const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

const buildHeaders = () => {
	return {
		...defaultHeaders,
	};
};

// const buildHeadersWithCsrf = () => {
//     return {
//         'X-CSRFToken': getCookie('csrftoken'),
//         ...defaultHeaders,
//     }
// }

const extractErrorMessage = (error) => {
	if (error.message) {
		return error.message
	} else {
		switch (error.type) {
			case 'required':
				return 'This field is required'
			case 'server':
				return error.message
		}
	}
	
	return "Oops! This field has errors"
}

const checkStatus = (response) => {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}
	
	const error = new Error(response.statusText);
	error.response = response;
	throw error;
};

const httpGet = (url) => {
	return fetch(url, {
		headers: buildHeaders(),
		credentials: 'same-origin',
	})
	.then(checkStatus)
	.then(parseJSON);
}

const httpPostWithCsrfToken = (url, csrf, data) => {
	return fetch(url, {
		method: 'post',
		headers: {
			'X-CSRFToken': csrf,
			...defaultHeaders,
		},
		body: JSON.stringify(data),
		credentials: 'same-origin',
	})
	.then(checkStatus)
	.then(parseJSON);
}

const httpPost = (url, data) => {
	return fetch(url, {
		method: 'post',
		headers: buildHeaders(),
		body: JSON.stringify(data),
		credentials: 'same-origin',
	})
	.then(checkStatus)
	.then(parseJSON);
}


export {
	extractErrorMessage,
	httpPostWithCsrfToken,
	httpGet,
	httpPost
};
