import { useState, useRef } from "react";
import InputPlaceholder from "./InputPlaceholder";

export default function SelectInputNoContext({ options, name, value, onChange, placeholder, className = '' }) {
  const [isOpen, setOpen] = useState(false);
  const [dropdownHeightVw, setDropdownHeightVw] = useState(null);
  const dropdownRef = useRef(null);

  const handleSelect = (val) => {
    onChange(val);
    setOpen(false);
  };

  return (
    <div className={className}>
      <div className="relative">
        <label
          className="flex items-center form-select-input w-full relative z-10 cursor-pointer"
          data-open={isOpen}
          onClick={() => setOpen(!isOpen)}
        >
          <InputPlaceholder placeholder={placeholder} value={value} />

          <span className="sm:mt-vw-3.5 mt-vw-10">
            {options.find(o => o.value === value)?.name}
          </span>

          <svg className="sm:w-vw-3 w-vw-8 absolute top-1/2 sm:right-vw-8 right-vw-16 -translate-y-1/2" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 9L0 1.82175L1.77651 0L7 5.38369L12.2235 0.0271899L14 1.84894L7 9Z" fill="black" />
          </svg>
        </label>

        <div
          ref={dropdownRef}
          className={`
            bg-white sm:rounded-b-vw-3xl rounded-b-vw-5xl
            absolute inset-x-0 sm:bottom-vw-8 bottom-vw-14 z-5
            translate-y-full transition-[max-height] overflow-hidden
            ring-2 ring-gray
          `}
          style={{
            maxHeight: isOpen ? `${dropdownHeightVw}vw` : dropdownHeightVw ? '0vw' : 'unset',
            visibility: isOpen ? 'visible' : 'hidden',
          }}
        >
          <div className="sm:pt-vw-8 pt-vw-14 sm:px-vw-4 px-vw-12 sm:pb-vw-4 pb-vw-10">
            <div className="grid grid-cols-2 w-full gap-y-px text-center">
              {options.map((opt, idx) => (
                <div key={idx}>
                  <button className="sm:-ml-vw-8 -ml-vw-6" type="button" onClick={() => handleSelect(opt.value)}>
                    <p
                      className={`
                        sm:text-vw-base text-vw-3xl
                        border-2 border-transparent sm:rounded-vw-xl rounded-vw-3xl
                        ${value === opt.value ? 'bg-primary text-white' : 'hover:border-primary'}
                        transition-[border]
                        sm:py-vw-2 py-vw-3 sm:px-vw-4 px-vw-4
                      `}
                    >
                      {opt.name}
                    </p>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
