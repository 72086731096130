import { basePageWrap } from '../BasePage';

import HeroSection from './sections/HeroSection';
import InquiryForm from './sections/InquiryForm';
import FeedbackSection from './sections/FeedbackSection';
import HowItWorksSection from './sections/HowItWorksSection';
import PhotographerSection from './sections/PhotographerSection';
import ShopSection from './sections/ShopSection';
import WhatYouGetSection from './sections/WhatYouGetSection';
import { ShopContextProvider } from '../../contexts/ShopContext';
import { OrderContextProvider } from '../../contexts/OrderContext';
import FAQSection from './sections/FAQSection';


function HomePage({ heroImage, heroTitle, products, interiorStyles, apartmentTypes, apartmentRooms, siteSetting, csrfToken }) {
	return (
		<OrderContextProvider {...{ products, csrfToken }}>
			<ShopContextProvider {...{ products, interiorStyles, apartmentTypes, apartmentRooms }}>
				<article className="w-full">
					<HeroSection {...{ heroImage, heroTitle, siteSetting }} />
					<InquiryForm {...{ siteSetting }} className="sm:mt-vw-24 mt-vw-40" />
					<HowItWorksSection className="sm:mt-vw-24 mt-vw-40" />
					<WhatYouGetSection className="sm:mt-vw-24 mt-vw-72" />
					<PhotographerSection className="sm:mt-vw-24 mt-vw-40" />
					<ShopSection {...{ siteSetting, csrfToken }} className="mt-vw-64" />
					<InquiryForm {...{ siteSetting }} className="sm:mt-vw-24 mt-vw-40" />
					<FeedbackSection {...{ siteSetting }} className="sm:mt-vw-24 mt-vw-40" />
					<FAQSection className="mt-vw-40" />
				</article>
			</ShopContextProvider>
		</OrderContextProvider>
	)
}


export default basePageWrap(HomePage);
