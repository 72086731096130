import Image from 'next/image';
// import Link from 'next/link';
import { trackWhatsAppClick } from '../../../utils/analytics';



export default function HeroSection({ heroImage, heroTitle, siteSetting }) {
  return (
    <section className="container-main">
      <div className="w-full sm:h-[80vh] h-[65vh] relative">
        <Image
          className="w-full object-cover"
          src={heroImage.src}
          alt={heroImage.alt}
          fill={true}
          quality={70}
          priority
          placeholder={heroImage.blurDataURL ? "blur" : "empty"}
          blurDataURL={heroImage.blurDataURL ? heroImage.blurDataURL : undefined}
        />

        <div className="absolute left-vw-9 sm:bottom-vw-16 bottom-vw-28 z-10">
          <h1 className="text-vw-7xl text-white font-bold sm:whitespace-pre-wrap">
            {heroTitle}
          </h1>
          <a 
          className="big-ass-button mt-4 inline-block"
          href={
            siteSetting.whatsappText 
              ? `${siteSetting.whatsappUrl}?text=${encodeURIComponent(siteSetting.whatsappText)}`
              : siteSetting.whatsappUrl
          }
          target="_blank"
          rel="noreferrer noopener"
          aria-label="WhatsApp"
          onClick={trackWhatsAppClick}
          >
              {"Text us"}
            </a>
        </div>
      </div>

      {/* <div className="mobile-only flex justify-start mt-vw-12">
        <Link className="big-ass-button block" href="#shop" scroll={false}>
          Let's get started
        </Link>
      </div> */}
    </section>
  )
}
