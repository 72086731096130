import { memo } from 'react';
import Image from 'next/image'

import { QuantityInput } from '../Forms';


function ProductCard({ size, data, quantity, disabled, className='' }) {
  if (size === 'big') {
    return (
      <div className={`flex h-vw-68 col-span-6 bg-white rounded-vw-4xl relative transition-opacity ${className}`}>
        <div className="flex-shrink-0 w-vw-116 h-full p-vw-10">
          <div className="w-full h-full relative">
            <Image className="object-contain" src={data.image.src} alt={data.image.alt} fill={true} />
          </div>
        </div>

        <div className="flex flex-col flex-grow py-vw-7">
          <h4 className="text-vw-base font-bold uppercase">{data.name}</h4>
          <p className="w-vw-60 text-vw-sm whitespace-pre-wrap mt-vw-2">
            {data.description}
          </p>

          <p className="text-vw-sm whitespace-pre-wrap mt-vw-4">
            {data.sizesText}
          </p>
        </div>

        <div className="centered-y flex-shrink-0 relative z-20 pr-vw-3">
          <QuantityInput
            name={`products.${data.id}.quantity`}
            rules={{ disabled }}
          />
        </div>

        {quantity === 0 && (
          <div className="backdrop-grayscale bg-gray/50 absolute inset-0 z-10" />
        )}
      </div>
    )
  } else if (size === 'medium') {
    return (
      <div className={`flex h-vw-90 col-span-3 bg-white rounded-vw-4xl relative transition-opacity ${className}`}>
        <div className="flex flex-col flex-grow pt-vw-7 pl-vw-7">
          <div className="flex-shrink-0 w-full h-vw-20">
            <h4 className="text-vw-base font-bold uppercase">{data.name}</h4>
            <p className="w-vw-60 text-vw-sm whitespace-pre-wrap line-clamp-3 mt-vw-2">
              {data.description}
            </p>
          </div>

          <div className="flex-grow w-full py-vw-4 pr-vw-4">
            <div className="w-full h-full relative">
              <Image className="object-contain object-left" src={data.image.src} alt={data.image.alt} fill={true} />
            </div>
          </div>

          <div className="h-vw-24 mt-vw-2">
            <p className="text-vw-sm whitespace-pre-wrap">
              {data.sizesText}
            </p>
          </div>
        </div>

        <div className="centered-y flex-shrink-0 relative z-20 pr-vw-3">
          <QuantityInput
            name={`products.${data.id}.quantity`}
            rules={{ disabled }}
          />
        </div>

        {quantity === 0 && (
          <div className="backdrop-grayscale bg-gray/50 absolute inset-0 z-10" />
        )}
      </div>
    )
  } else if (size === 'small') {
    return (
      <div className={`flex flex-col h-vw-90 col-span-2 bg-white rounded-vw-4xl relative transition-opacity pt-vw-7 ${className}`}>
        <div className="flex h-vw-20 pl-vw-7">
          <div className="flex-grow min-w-0">
            <h4 className="text-vw-base font-bold uppercase">{data.name}</h4>
            <p className="text-vw-sm whitespace-pre-wrap line-clamp-3 mt-vw-2">
              {data.description}
            </p>
          </div>

          <div className="centered-y flex-shrink-0 relative z-20 pr-vw-3">
            <QuantityInput
              name={`products.${data.id}.quantity`}
              rules={{ disabled }}
            />
          </div>
        </div>

        <div className="flex-grow w-full py-vw-4 px-vw-7">
          <div className="w-full h-full relative">
            <Image className="object-contain object-left" src={data.image.src} alt={data.image.alt} fill={true} />
          </div>
        </div>

        <div className="h-vw-22 mt-vw-2 px-vw-7">
          <p className="text-vw-sm whitespace-pre-wrap">
            {data.sizesText}
          </p>
        </div>

        {quantity === 0 && (
          <div className="backdrop-grayscale bg-gray/50 absolute inset-0 z-10" />
        )}
      </div>
    )
  } else if (size === 'mobile') {
    return (
      <div className={`flex flex-col bg-white sm:rounded-vw-4xl rounded-vw-6xl relative transition-opacity ${className}`}>
        <div className="w-full h-vw-100 p-vw-10">
          <div className="w-full h-full relative">
            <Image className="object-contain" src={data.image.src} alt={data.image.alt} fill={true} />
          </div>
        </div>

        <div className="flex pt-vw-7 px-vw-10 pb-vw-14">
          <div className="pr-vw-8">
            <div className="flex-grow min-w-0">
              <h4 className="text-vw-3xl font-bold uppercase">{data.name}</h4>
              <p className="text-vw-2xl line-clamp-3 mt-vw-2">
                {data.description}
              </p>
            </div>

            <p className="text-vw-2xl whitespace-pre-wrap mt-vw-4">
              {data.sizesText}
            </p>
          </div>

          <div className="flex justify-end items-start flex-shrink-0 w-vw-24 relative z-20">
            <QuantityInput
              name={`products.${data.id}.quantity`}
              rules={{ disabled }}
            />
          </div>
        </div>

        {quantity === 0 && (
          <div className="backdrop-grayscale bg-gray/50 absolute inset-0 z-10" />
        )}
      </div>
    )
  }
}

ProductCard.propTypes = {};

ProductCard.defaultProps = {};


export default memo(ProductCard)
