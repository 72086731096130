import { useState, createContext, useContext, useEffect } from "react";
import { useOrderContext } from "./OrderContext";


const ShopContext = createContext({})


export function ShopContextProvider({ products, interiorStyles, apartmentTypes, apartmentRooms, children }) {
  const { getValues } = useOrderContext()
  const [currentInteriorStyle, setCurrentInteriorStyle] = useState(interiorStyles?.[0]?.slug);
  const [currentApartmentType, setCurrentApartmentType] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getProductsCount = (styleSlug = currentInteriorStyle, typeSlug = currentApartmentType, roomSlug = null) => {
    let prods = products.filter(prod => 
      prod.interiorStyle.slug === styleSlug
      && !!prod.apartmentTypes.find(type => type.slug === typeSlug)
    );

    if (roomSlug) {
      prods = prods.filter(prod => !!prod.apartmentRooms.find(room => room.slug === roomSlug))
    }

    if (styleSlug === currentInteriorStyle && typeSlug === currentApartmentType) {
      const formProds = getValues('products');

      return prods.reduce((count, prod) => {
        let formProd = formProds?.[prod.id];
        return formProd ? count + formProd.quantity : count
      }, 0)
    }

    return prods.reduce((count, prod) => {
      return count + prod.defaultQuantity
    }, 0)
  }

  useEffect(() => {
    if (currentInteriorStyle) {
      const controller = new AbortController();
      const signal = controller.signal;

      setLoading(true);
      setError(null);

      fetch(`/wt/api/shop/v1/gallery_images_for_style/${currentInteriorStyle}`)
        .then(res => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.json();
        })
        .then(data => {
          setGalleryImages(data);
          setLoading(false);
        })
        .catch(error => {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted');
          } else {
            setError(error);
            setLoading(false);
          }
        });

      return () => controller.abort();
    }
  }, [currentInteriorStyle]);

  return (
    <ShopContext.Provider
      value={{
        products,
        interiorStyles,
        currentInteriorStyle,
        setCurrentInteriorStyle,
        apartmentTypes,
        currentApartmentType,
        setCurrentApartmentType,
        apartmentRooms,
        getProductsCount,
        galleryImages,
        setGalleryImages,
        loading
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}


export const useShopContext = () => useContext(ShopContext);
