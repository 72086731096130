import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';


function BeforeAfterImage({ leftImage, rightImage, className }) {
  return (
    <ReactBeforeSliderComponent
      secondImage={leftImage}
      firstImage={rightImage}
      className={className}
    />
  )
}

BeforeAfterImage.propTypes = {};

BeforeAfterImage.defaultProps = {};


export default BeforeAfterImage
