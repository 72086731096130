import React from 'react'


export default function PolicyText({ url }) {
  return (
    <>
      I agree with <a href={url} target="_blank" rel="noopener noreferrer" className="underline">privacy policy</a>
    </>
  )
}
